import { Card, CircularProgress, Grid, Slider, Stack, styled, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment-timezone';
import qs from 'query-string';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba';
import Loader from 'src/components/loader';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import UserLayout from 'src/layouts/UserLayout';
import { getProjectExtraNavItems } from 'src/navigation/vertical';
import { request } from 'src/utils/request';
import ActivityTimeline from './ActivityTimeline';
import DonutChart from './DonutChart';
import MeetingSchedule from './MeetingSchedule';
import TotalGrowth from './TotalGrowth';
import { getRoles, setProject } from 'src/store/actions/application';
import { useEffect } from 'react';
import RoleTable from './RoleTable';
import { getLocalizationValue } from 'src/utils/common';
import { useProjectDetail } from 'src/hooks';

const Loading = () => <CircularProgress sx={{ display: 'block', margin: 'auto' }} />;

const StatisticWrapper = ({ isLoading, sx, children }) => (
  <Card sx={{ padding: '20px', height: '100%', ...sx }}>{isLoading ? <Loading /> : children}</Card>
);

const MuiDateRange = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    // '& *': {
    //   background: 'transparent',
    //   color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    // },
  },
  '& .MuiSlider-thumb': { width: '0' },
  '& .MuiSlider-track': { border: 'none', borderRadius: '0', background: theme.palette.primary.main },
  '& .MuiSlider-rail': { backgroundColor: theme.palette.primary.main },
}));

const DateRangeValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  margin: '0 10px',
}));

function ProjectsDetailPage() {
  // const currentProjectUuid = localStorage.getItem('project');
  // getRoles(currentProjectUuid);
  const { uuid } = useParams();
  const theme = useTheme();
  const roles = useSelector((state) => state.application.roles);

  const roleByUuid = roles.reduce((p, c) => ({ ...p, [c.id]: c }), {});

  const { isLoading, data } = useProjectDetail({ projectUuid: uuid });

  const fetchProjectStatistics = async ({ queryKey }) => {
    const [_, projectUuid] = queryKey;
    const response = await request(`/internal/projects/${projectUuid}/sumary`);
    return response.message;
  };

  const { isLoading: isLoadingStatistics, data: projectStatistics } = useQuery(
    ['fetchProjectStatistics', uuid],
    fetchProjectStatistics,
    {
      enabled: !!uuid,
    }
  );

  useEffect(() => {
    getRoles(uuid);
  }, [uuid]);

  const dateStartPlan = moment.unix(data?.dateStartPlan);
  const dateFinishPlan = moment.unix(data?.dateFinishPlan);
  const installationDatePlan = moment.unix(data?.installationDatePlan);
  const deinstallationDatePlan = moment.unix(data?.deinstallationDatePlan);
  const dateRangeValue = dateFinishPlan.clone().diff(dateStartPlan, 'days');
  const minValue = Math.floor((installationDatePlan.clone().diff(dateStartPlan, 'days') / dateRangeValue) * 100);
  const maxValue = Math.ceil((deinstallationDatePlan.clone().diff(dateStartPlan, 'days') / dateRangeValue) * 100);

  const noReadyCount =
    projectStatistics?.totalCount -
    projectStatistics?.readyToAccreditateCount -
    projectStatistics?.isAccreditedCount -
    projectStatistics?.isRejectedCount;
  const visitorStatusStatistics = [
    {
      label: 'На проверка',
      value: projectStatistics?.readyToAccreditateCount || 0,
    },
    {
      label: 'Аккредитован',
      value: projectStatistics?.isAccreditedCount || 0,
    },
    {
      label: 'Зарегистрирован',
      value: noReadyCount || 0,
    },
    {
      label: 'Отклонено',
      value: projectStatistics?.isRejectedCount || 0,
    },
  ];

  const visitorTypeStatistics =
    projectStatistics?.countByRoles?.map((role) => {
      const roleUuid = role?.roleUuid;
      return {
        label: roleByUuid[roleUuid]?.name?.ru_RU || 'Нет роли',
        value: role?.count || 0,
      };
    }) || [];

  return (
    <UserLayout showExtraNavItems={true}>
      <Stack gap="24px">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h4" fontWeight="bold" textAlign="center">
              {getLocalizationValue(data, 'fullName')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <StatisticWrapper isLoading={isLoadingStatistics}>
              <Stack direction="row" justifyContent="space-between" gap="30px">
                <Typography variant="body1" fontWeight={600}>
                  Количество заявок
                </Typography>
                <Typography variant="h5">{projectStatistics?.totalCount}</Typography>
              </Stack>
            </StatisticWrapper>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3}>
            <StatisticWrapper isLoading={isLoadingStatistics}>
              <Stack direction="row" justifyContent="space-between" gap="30px">
                <Typography variant="body1" fontWeight={600}>
                  Распечатано билетов
                </Typography>
                <Typography variant="h5">{projectStatistics?.ticketCount}</Typography>
              </Stack>
            </StatisticWrapper>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <StatisticWrapper isLoading={isLoading}>
              <Stack direction="row" justifyContent="center" height="60px" alignItems="flex-end">
                <DateRangeValue variant="body2">
                  {moment.unix(data?.dateStartPlan).format(DEFAULT_DATE_FORMAT)}
                </DateRangeValue>
                {minValue && maxValue && (
                  <MuiDateRange
                    defaultValue={[minValue, maxValue]}
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => {
                      return (
                        <Typography variant="body2">
                          {value === minValue
                            ? minValue === 0
                              ? ''
                              : installationDatePlan.format(DEFAULT_DATE_FORMAT)
                            : maxValue === 100
                            ? ''
                            : deinstallationDatePlan.format(DEFAULT_DATE_FORMAT)}
                        </Typography>
                      );
                    }}
                    disabled={true}
                  />
                )}
                <DateRangeValue variant="body2">
                  {moment.unix(data?.dateFinishPlan).format(DEFAULT_DATE_FORMAT)}
                </DateRangeValue>
              </Stack>
            </StatisticWrapper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {isLoadingStatistics ? (
              <Loader />
            ) : (
              <DonutChart title="Статус посетителя" statistics={visitorStatusStatistics} />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            {isLoadingStatistics ? (
              <Loader />
            ) : (
              <DonutChart title="Тип посетителя" statistics={visitorTypeStatistics} />
            )}
          </Grid>

          {projectStatistics && (
            <Grid item xs={12}>
              <RoleTable projectUuid={uuid} projectStatistics={projectStatistics} />
            </Grid>
          )}
          <Grid item xs={12}>
            <TotalGrowth statistics={projectStatistics?.countByCreatedAt} />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <StatisticWrapper isLoading={isLoadingStatistics}>
              <Stack direction="row" justifyContent="space-between" gap="30px">
                <Typography variant="body1" fontWeight={600}>
                  Количество визитка
                </Typography>
                <Typography variant="h5">{projectStatistics?.vizitCount}</Typography>
              </Stack>
            </StatisticWrapper>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <StatisticWrapper isLoading={isLoadingStatistics}>
              <Stack direction="row" justifyContent="space-between" gap="30px">
                <Typography variant="body1" fontWeight={600}>
                  Количество встреч
                </Typography>
                <Typography variant="h5">{projectStatistics?.meetingCount}</Typography>
              </Stack>
            </StatisticWrapper>
          </Grid>
        </Grid>
      </Stack>
    </UserLayout>
  );
}

export default ProjectsDetailPage;
